import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$link-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--link:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-primary-text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--breadcrumb-item::after`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`When a user hovers overs a breadcrumb, the breadcrumb title should be underlined.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.173913043478258%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAA2UlEQVQY02NggIGk/xA6Dkgn/WNgSP7PwBjzlsEWKMQU84qBIeU/A1vUfQaGsK8MqoWvGMJbz4KVb14xDaEXA6TiksAONi6bhkc2GWpYwn8QmweIBZljXnHpMTAwMsT85ANaJsgedZebIew/n3LhW7mwlrOyeF1oG9kOpr3DMhgsoiZwqsZuZ2dI/M8Y4ufKYRnRySmSeIcVGByMSgGz2D0rDnGX92/hAalfvngOdgPjQ13h7LhQN47cYC0WEDs8MoY1LsyTc6s7NFTCHdlK9ICuBoKejiYMcwBtdEUDMTGWfgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Breadcrumb typography treatment example",
          "title": "Breadcrumb typography treatment example",
          "src": "/static/897a1eb44eb9dfe57a21db2e5b393b1d/fb070/breadcrumb-style-1.png",
          "srcSet": ["/static/897a1eb44eb9dfe57a21db2e5b393b1d/d6747/breadcrumb-style-1.png 288w", "/static/897a1eb44eb9dfe57a21db2e5b393b1d/09548/breadcrumb-style-1.png 576w", "/static/897a1eb44eb9dfe57a21db2e5b393b1d/fb070/breadcrumb-style-1.png 1152w", "/static/897a1eb44eb9dfe57a21db2e5b393b1d/c3e47/breadcrumb-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Breadcrumb typography treatment example</Caption>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The on-click dropdown should follow the `}<a parentName="p" {...{
        "href": "/components/overflow-menu"
      }}>{`overflow menu`}</a>{` specs for sizing, padding, and interaction.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px/rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--breadcrumb-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.16304347826087%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA1ElEQVQY02NggIGk/xA68T8DazqQ7fCfIfTwBwZkwJh2hkGai4FBXEKKQVxcHIxBAEZjgsT/WAQZGXgX2TDh0IHHMJgLE/4zsGT8ZGfQ/c/ivOWaiO7CGSIMsct5mQpucDNG/GeREOViA7qQHWgQK34XJv2DM5lT/3MzBP3lM1xyTC56z2JuBqP/HEyp//kZEn9ySAqycgEN5AcawgEzDLcro4GuLFvEQCzAbRDMy/kQbzOnf2VgcPnP8P//f4bsfQuA7HcMTGlAucTfDEAXwiMFmwsBTO05oKT+3EgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Truncated breadcrumb dropdown example",
          "title": "Truncated breadcrumb dropdown example",
          "src": "/static/97c43b9e9987605d6207dd30466d9cbc/fb070/breadcrumb-style-2.png",
          "srcSet": ["/static/97c43b9e9987605d6207dd30466d9cbc/d6747/breadcrumb-style-2.png 288w", "/static/97c43b9e9987605d6207dd30466d9cbc/09548/breadcrumb-style-2.png 576w", "/static/97c43b9e9987605d6207dd30466d9cbc/fb070/breadcrumb-style-2.png 1152w", "/static/97c43b9e9987605d6207dd30466d9cbc/c3e47/breadcrumb-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing measurements for breadcrumb | px / rem</Caption>
    <h2 {...{
      "id": "recommended"
    }}>{`Recommended`}</h2>
    <p>{`Truncated breadcrumbs are not currently built into the breadcrumb component. The following colors from the `}<a parentName="p" {...{
        "href": "/overflow/style"
      }}>{`overflow menu`}</a>{` are recommended for the overflow breadcrumb list.`}</p>
    <h3 {...{
      "id": "color-1"
    }}>{`Color`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__option:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-row`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "typography-1"
    }}>{`Typography`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "structure-1"
    }}>{`Structure`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.255434782608695%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABXElEQVQoz2NgwAZqKxE0MkaWIwQ8U/+C6Zu5vxm0M/4xMDj/Y/A/+BtFDXPGaQYpTiYGcQlJBnFxcTAGARiNAe7n/cYQy1uxh7Hi8FzWD19mMP///58RiFHkxcXFsBtmmfYP4sKc/wyymb84GEz+sxitvyrIUJzMBxRmRFaroaPCzsXHwgpzmYiYIKaBZckwL39jDE35K87k/FfAY9E15VNXb+ZduPQ86/iVz0k3H3xJPXrglCkDAyuvg2E4r6SENFiPlpwNnsBMuw1nvnlxj/fRw0d3X754/v/Rwwd///75/f/t23d1IDmg15lAtKiYMA6DWv8zaMRBvL3u8muwF//8+cf34OGDJ2/evPn/5MnT/yDw6tWrJpDcrTvXmEXFhCB6ebCYNzf+D5iuzLvN8PrZBzD78/fvLC9fvnR++vRpwIsXL3w/fPgQBMRqILkPHz4ydNpBIrHLDhGZAPNvmO1SGwneAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Truncated breadcrumb dropdown example",
          "title": "Truncated breadcrumb dropdown example",
          "src": "/static/23952a7fbac49b38629e47bd8001525e/fb070/breadcrumb-style-3.png",
          "srcSet": ["/static/23952a7fbac49b38629e47bd8001525e/d6747/breadcrumb-style-3.png 288w", "/static/23952a7fbac49b38629e47bd8001525e/09548/breadcrumb-style-3.png 576w", "/static/23952a7fbac49b38629e47bd8001525e/fb070/breadcrumb-style-3.png 1152w", "/static/23952a7fbac49b38629e47bd8001525e/c3e47/breadcrumb-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Recommended structure and spacing measurements for breadcrumb | px / rem
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      